import React from "react"
import Layout from "../components/Layouts/Layout/Layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import ContentBuilder from "../components/sections/ContentBuilder"
import fleche from "../images/fleche-gauche.svg"
import componentFactory from "../utils/componentFactory"
import * as questionsTemplateStyles from "./questions-template.module.scss"

const QuestionsTemplate = ({ pageContext }) => {
  const item = pageContext.item.attributes
  const page = pageContext.page.attributes
  const components = page.components.map(i => componentFactory(i, pageContext))

  return (
    <>
      <Layout>
        <div className={questionsTemplateStyles.questionContainer}>
          <Link
            to="/assistance-locataire"
            className={questionsTemplateStyles.retourLink}
          >
            <img
              src={fleche}
              alt="icone flèche"
              className={questionsTemplateStyles.arrow}
            />
            Retour
          </Link>
        </div>

        <section>
          <div className="container">
            <h2 className={`${questionsTemplateStyles.title} ${"fs-lg "}`}>
              {item.question}
            </h2>
          </div>
        </section>
        <ContentBuilder html={item.content} />
        {components}
      </Layout>
    </>
  )
}
export const Head = ({ pageContext }) => (
  <Seo
    title={pageContext.item.attributes.question}
    description={pageContext.item.attributes.desc}
  />
)
export default QuestionsTemplate
